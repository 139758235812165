.banner {
  .bannerImg {
    width: 100%;
    height: calc(100vh - 175px);
    object-fit: cover;

    &.mobBannerSize {
      height: unset;
    }
  }
}
