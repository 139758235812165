.homePage {
  .productHero {
    text-align: center;
    margin: 50px 0;

    img {
      width: 100%;
      max-width: 800px;
    }

    .productHeroDesc {
      font-size: 42px;
      font-weight: bold;
      max-width: 700px;
      margin: 0 auto;
      text-transform: uppercase;
    }
  }

  .productIngredientRow {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 20px;
    row-gap: 50px;
    margin-bottom: 50px;

    img {
      width: 262px;
      height: 262px;
      object-fit: contain;
      margin-bottom: 20px;
      // border-radius: 50%;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    .desc {
      font-size: 16px;
      text-align: center;
      line-height: normal;
    }
  }

  .productIngredientDesc {
    margin-bottom: 100px;
  }

  .mctBenefits {
    margin-bottom: 50px;
  }

  .gutHealth {
    margin: 50px 0;
  }
}

@media screen and (max-width: 1200px) {
  .homePage {
    .productIngredientRow {
      grid-template-columns: repeat(2, 1fr);
      margin-bottom: 30px;
    }
  }
}

@media screen and (max-width: 768px) {
  .homePage {
    .productHero {
      .productHeroDesc {
        font-size: 26px;
      }
    }

    .productIngredientRow {
      grid-template-columns: 1fr;
      margin-bottom: 30px;
    }

    .productIngredientDesc {
      ol,
      ul {
        padding-left: 10px;
      }
    }
  }
}
