.topbar {
  background-color: var(--secondary-bg-color);
  color: var(--sub-txt-color);

  .topbarContainer {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: space-between;
  }

  .logo {
    width: 200px;
  }

  .topbarMenu {
    display: flex;
    gap: 15px;
    align-items: center;
    font-weight: 700;
    flex-wrap: wrap;

    .topbarMenuItem {
      position: relative;

      .menuTitle {
        display: flex;
        gap: 15px;
        padding: 15px;
        cursor: pointer;

        svg {
          transition: transform 0.2s;
        }
      }

      .menuTitle:hover,
      .menuTitle.active {
        color: var(--primary-txt-color);
      }

      .menuTitle.active {
        svg {
          transform: rotate(180deg);
        }
      }

      .menuChild {
        position: absolute;
        top: 50px;
        left: 0;
        background-color: var(--secondary-bg-color);
        min-width: 300px;
        z-index: 2;

        .grandChildTitle.active {
          color: var(--primary-txt-color);
        }

        .links:hover {
          color: var(--primary-txt-color);
        }
      }
    }
  }

  .profileContainer {
    display: flex;
    gap: 20px;
    align-items: center;
    font-size: 25px;

    .cartQty {
      position: absolute;
      bottom: -10px;
      right: -10px;
      font-size: 12px;
      background-color: var(--primary-bg-color);
      padding: 5px 8px;
      border-radius: 50%;
    }
  }
}

@media screen and (max-width: 768px) {
  .topbar {
    .logo {
      width: 140px;
    }

    .topbarMenu {
      display: none;
    }
  }
}
