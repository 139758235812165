@font-face {
  font-family: "Metropolis";
  src: url("../fonts/Metropolis-Medium.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Metropolis";
  src: url("../fonts/Metropolis-ExtraBold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Metropolis";
  src: url("../fonts/Metropolis-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

:root {
  // Theme
  --primary-color: #d01d23;
  --secondary-color: #000000;
  --sub-color: #ffffff;
  --grey-color: #e5e5e5;

  /* Background */
  --primary-bg-color: var(--primary-color);
  --secondary-bg-color: var(--secondary-color);
  --sub-bg-color: var(--sub-color);
  --grey-bg-color: var(--grey-color);

  /* Text */
  --primary-txt-color: var(--primary-color);
  --secondary-txt-color: var(--secondary-color);
  --sub-txt-color: var(--sub-color);

  /* Border */
  --border-grey: #dadada;
  --border-red: #de1c24;
  --border-light-black: rgba(255, 255, 255, 0.12);

  /* Gradient */
  --gradient-bg-color-1: linear-gradient(#77cffd, #37a9ed, #3fb4fd);

  /* Shadow */
  --shadow-color: 0px 3px 6px rgba(44, 109, 173, 0.2);

  /* Dividers */
  --divider-color: rgba(14, 13, 28, 0.12);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Metropolis", sans-serif;
}

.container {
  max-width: 1440px;
  margin: 0 auto;
  width: 85%;
}

.container-sm {
  max-width: 1000px;
  margin: 0 auto;
  width: 90%;
}

.primary-bg {
  background-color: var(--primary-bg-color);
}

.secondary-bg {
  background-color: var(--secondary-bg-color);
}

a,
a:hover,
a:focus {
  text-decoration: unset;
  color: unset;
}

.active {
  color: #de1c24;
}

.text-red {
  color: #de1c24 !important;
}

.text-black {
  color: #000 !important;
}

.text-white {
  color: #fff !important;
}

.text-gray {
  color: #777 !important;
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.j-c-center {
  justify-content: center;
}

.j-c-sb {
  justify-content: space-between;
}

.j-c-start {
  justify-content: flex-start;
}

.j-c-end {
  justify-content: flex-end;
}

.a-i-center {
  align-items: center;
}

.a-i-end {
  align-items: flex-end;
}

.a-i-start {
  align-items: flex-start;
}

.a-s-center {
  align-self: center;
}

.a-s-end {
  align-self: flex-end;
}

.a-s-start {
  align-self: flex-start;
}

.a-s-c {
  align-self: center;
}

.grid {
  display: grid;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-550 {
  font-weight: 550 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.font-bold {
  font-weight: bold !important;
}

.font-italic {
  font-style: italic;
}

.bg-transparent {
  background-color: transparent;
}

.bg-gray {
  background-color: #e5e5e5;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-underline {
  text-decoration: underline !important;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

.p-sticky {
  position: sticky;
}

.bottom-shadow {
  box-shadow: 0 -6px 22px #0000001f;
}

.full-width {
  width: 100%;
}

.half-width {
  width: 50%;
}

.full-height {
  height: 100%;
}

.min-height-vh {
  min-height: 100vh;
}

.outline-0 {
  outline: 0;
}

.o-hidden {
  overflow: hidden;
}

.absolute-middle {
  top: 50%;
  transform: translateY(-50%);
}

.d-none {
  display: none;
}

.cursor-pointer {
  cursor: pointer;
}

.big-title {
  font-size: 40px;
  font-weight: bold;
}

.big-sub-title {
  font-size: 30px;
  font-weight: 300;
  // letter-spacing: 1px;
}

.big-quote {
  font-size: 48px;
  line-height: 1.2em;
}

.paragraph {
  line-height: 25px;
  font-weight: 300;
}

.btn {
  cursor: pointer;
  border: 0;
  transition: all 0.3s ease;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
}

.btn-primary {
  color: var(--sub-txt-color);
  background: var(--primary-bg-color);
}

.btn-sub {
  color: var(--primary-txt-color);
  background: var(--sub-bg-color);
  border: 1px solid var(--primary-color);
}

.btn-large {
  min-height: 42px;
}

.m-l-auto {
  margin-left: auto;
}

.m-r-auto {
  margin-right: auto;
}

.m-t-auto {
  margin-top: auto;
}

.m-b-auto {
  margin-bottom: auto;
}

.object-cover {
  object-fit: cover;
}

@for $i from 0 through 500 {
  .p-#{$i} {
    padding: #{$i}px;
  }

  .p-t-#{$i} {
    padding-top: #{$i}px !important;
  }

  .p-b-#{$i} {
    padding-bottom: #{$i}px !important;
  }

  .p-l-#{$i} {
    padding-left: #{$i}px !important;
  }

  .p-r-#{$i} {
    padding-right: #{$i}px !important;
  }

  .p-x-#{$i} {
    padding-left: #{$i}px !important;
    padding-right: #{$i}px !important;
  }

  .p-y-#{$i} {
    padding-top: #{$i}px !important;
    padding-bottom: #{$i}px !important;
  }

  .m-#{$i} {
    margin: #{$i}px;
  }

  .m-t-#{$i} {
    margin-top: #{$i}px !important;
  }

  .m-b-#{$i} {
    margin-bottom: #{$i}px !important;
  }

  .m-l-#{$i} {
    margin-left: #{$i}px !important;
  }

  .m-r-#{$i} {
    margin-right: #{$i}px !important;
  }

  .m-x-#{$i} {
    margin-left: #{$i}px !important;
    margin-right: #{$i}px !important;
  }

  .m-y-#{$i} {
    margin-top: #{$i}px !important;
    margin-bottom: #{$i}px !important;
  }

  .gap-#{$i} {
    gap: #{$i}px;
  }

  .fs-#{$i} {
    font-size: #{$i}px !important;
  }

  .grid-#{$i}-col {
    grid-template-columns: repeat(#{$i}, 1fr);
  }

  .border-#{$i} {
    border: #{$i}px solid;
  }

  .border-bottom-#{$i} {
    border-bottom: #{$i}px solid;
  }

  .w-#{$i} {
    width: #{$i}px !important;
  }

  .h-#{$i} {
    height: #{$i}px !important;
  }

  .b-r-#{$i} {
    border-radius: #{$i}px !important;
  }

  .left-#{$i} {
    left: #{$i}px !important;
  }

  .right-#{$i} {
    right: #{$i}px !important;
  }

  .top-#{$i} {
    top: #{$i}px !important;
  }

  .bottom-#{$i} {
    bottom: #{$i}px !important;
  }

  .z-index-#{$i} {
    z-index: #{$i} !important;
  }

  .min-h-#{$i} {
    min-height: #{$i}px !important;
  }

  .max-h-#{$i} {
    max-height: #{$i}px !important;
  }

  .letterSpace-#{$i} {
    letter-spacing: 5px;
  }

  .max-w-#{$i} {
    max-width: #{$i}px !important;
  }
}

.border-grey {
  border-color: var(--border-grey);
}

.quoteSection {
  .big-quote {
    padding-bottom: 4em;
  }
}

// Sticky topbar
.sticky {
  position: sticky;
  top: 0;
  z-index: 1000;
}

// Parallax
.parallax {
  min-height: 600px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.parallax.short {
  min-height: 400px;
}

// Swiper
.swiper-button-prev:after,
.swiper-button-next:after {
  font-size: 12px !important;
  color: #fff !important;
}

.main-page .swiper-pagination-fraction {
  color: #fff !important;
}

.commonMobSwiper {
  .swiper-button-prev,
  .swiper-button-next {
    background-color: var(--primary-bg-color);
    top: 50%;
    transform: translateY(-50%);
    margin-top: 0;
    width: 36px;
    height: 44px;
  }

  .swiper-button-prev {
    left: 0;
  }

  .swiper-button-next {
    right: 0;
  }
}

// Global Drawer
.ant-drawer {
  .custom-drawer {
    .ant-drawer-title {
      font-size: 20px;
      font-weight: bold;
      text-transform: uppercase;
    }

    .ant-drawer-close {
      position: absolute !important;
      right: 8px !important;
    }
  }
}

// Pages
@import "./AbsolutelyPage.scss";
@import "./AccountPage.scss";
@import "./AddressList.scss";
@import "./CartDrawer.scss";
@import "./CheckoutPage.scss";
@import "./ConditionPage.scss";
@import "./MenuDrawer.scss";
@import "./ProductListPage.scss";
@import "./ProductPage.scss";
@import "./Skew.scss";
@import "./ZoomImgDrawer.scss";
@import "./CommunityBlogPage.scss";
@import "./CommunityBlogItemPage.scss";

// Pro page
.proPage {
  .banner {
    display: flex;
    align-items: center;
  }
  .yogaImgContainer {
    position: relative;

    .yogaImg {
      width: 100%;
    }

    .imgBackdrop {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      background-image: linear-gradient(
        to top,
        transparent 0%,
        transparent 50%,
        #fff 100%
      );
    }
  }

  .productHero {
    text-align: center;
    margin: 50px 0;

    img {
      width: 100%;
      max-width: 800px;
    }

    .productHeroDesc {
      font-size: 42px;
      font-weight: bold;
      max-width: 700px;
      margin: 0 auto;
      text-transform: uppercase;
    }
  }

  .productIngredientRow {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 20px;
    row-gap: 50px;
    margin-bottom: 50px;

    img {
      width: 262px;
      height: 262px;
      object-fit: contain;
      margin-bottom: 20px;
      // border-radius: 50%;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    .desc {
      font-size: 16px;
      text-align: center;
      line-height: normal;
    }
  }

  .productIngredientDesc {
    margin-bottom: 100px;
  }

  .mctBenefits {
    margin-bottom: 50px;
  }

  .gutHealth {
    margin: 50px 0;
  }

  .beachSection {
    padding-top: 10em;
    .beachImg {
      // width: 100%;
      height: 600px;
      // object-fit: cover;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top;
    }
  }

  .beachSection:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10em;
    background-color: #fff;
    transform: skewY(5deg);
    transform-origin: top left;
  }

  .coconutSkew {
    .coconutText {
      width: 50%;
      margin-left: auto;
    }
  }
}

@media screen and (max-width: 1200px) {
  .homePage {
    .productIngredientRow {
      grid-template-columns: repeat(2, 1fr);
      margin-bottom: 30px;
    }
  }
}

@media screen and (max-width: 768px) {
  .container {
    width: 90%;
  }

  .big-title {
    font-size: 26px;
  }

  .parallax {
    min-height: 400px;
    background-attachment: inherit;
  }

  .parallax.short {
    min-height: 300px;
    background-attachment: inherit;
  }

  .paragraph {
    font-size: 14px;
  }

  .big-quote {
    font-size: 26px;
  }

  .homePage {
    .productHero {
      .productHeroDesc {
        font-size: 26px;
      }
    }

    .productIngredientRow {
      grid-template-columns: 1fr;
      margin-bottom: 30px;
    }

    .productIngredientDesc {
      ol,
      ul {
        padding-left: 10px;
      }
    }
  }

  .proPage {
    .banner {
      flex-direction: column;
      .half-width {
        width: 100%;
      }
    }
    .coconutSkew {
      padding-bottom: 6em !important;
      .coconutText {
        width: 100%;
        margin-left: unset;
        margin-top: 100px;
      }
    }
  }
}

.ant-spin-dot-item {
  background-color: var(--primary-color) !important;
}
